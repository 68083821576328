<template>
  <div class="wrapper">
    <div id="column" class="world">
      <section>
        <div class="activity">
          <ul>
            <li
              class="flex cursorP"
              v-for="(item, index) in activityList"
              :key="index"
              @click="goRouter('/hunan/skill/skillDetail',item.id)"
            >
              <img :src="item.imgurl" alt="" class="err_image" />
              <div class="text">
                <h3>
                  {{ item.name }}
                  <span class="one" v-show="item.auditstatus == '待审核'"
                    >未审核</span
                  >
                  <span class="two" v-show="item.auditstatus == '通过'"
                    >审核通过</span
                  >
                  <el-popover
                    placement="bottom"
                    title="未通过原因"
                    width="200"
                    trigger="click"
                    content="item.auditMsg"
                  >
                    <span class="three" v-show="item.auditstatus == '不通过'"
                      >未通过，查看原因</span
                    >
                  </el-popover>
                </h3>
                <p class="day" v-if="getTime(item.matchStartDate) > 0">距离比赛开始还有{{getTime(item.matchStartDate) }}天</p>
                <p  class="time">报名时间：{{ item.matchStartDate }}</p>
              </div>
            </li>
          </ul>
        </div>
      </section>

      <div class="pages">
        <el-pagination
          background
          class="work_pagination"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="params.pageNum"
          :page-size="params.pageSize"
          layout="prev, pager, next, total"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "column",
  data() {
    return {
      total: 0,
      activityList: [],
      params: {
        pageNum: 1,
        pageSize: 4,
      },
    };
  },
  methods: {
    async skillContest() {
      try {
        this.loading = true;
        let res = await this.$api.skillContest(this.params);
        if (res.data.success) {
          this.activityList = res.data.data.list;
          this.total = res.data.data.total;
          this.loading = false;
          console.log(this.activityList);
          // if (this.focusList.length <= 0) {
          //   this.status.isHide = 2;
          //   this.status.type = "post";
          //   this.status.des = "暂无关注数据！";
          // } else {
          //   this.status.isHide = 0;
          // }
        } else {
          this.$message.error(res.data.msg);
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },

    goRouter(url, id) {
      const { href } = this.$router.resolve({
        path: url,
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
    },
    getTime(time){
      let date = Date.parse(new Date(time));
      let today = Date.parse(new Date());
      let day = date - today;
      let days = Math.floor(day / (24 * 3600 * 1000));
      return days
    },

    //分页
    handleCurrentChange(val) {
      this.params.pageNum = val;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
    },
  },
  created() {
     this.skillContest() 
  },
};
</script>

<style scoped lang="less">
@import "~assets/css/public";
.world {
  padding: 1px 0;
  margin: 0 20px;
  .flex {
    display: flex;
  }
  .activity {
    ul {
      margin-top: 24px;
      li {
        margin-bottom: 20px;
        img {
          width: 176px;
          height: 120px;
          border-radius: 6px;
          margin-right: 17px;
        }
        .text {
          position: relative;
          h3 {
            font-size: 18px;
            font-weight: 600;
            margin: 8px 0 13px 0;
            width: 740px;
            position: relative;
            span {
              font-size: 16px;
              font-family: PingFang SC;
              color: #666666;
              line-height: 14px;
              position: absolute;
              right: 0;
              top: 3px;
            }
            .two {
              color: #28a46d;
            }
            .three {
              color: #e62220;
            }
          }
          p {
            color: #666666;
            font-size: 14px;
            line-height: 14px;
          }
          .day {
            margin-bottom: 34px;
            color: #e62220;
          }
          .time{
            position: absolute;
            top: 92px;
          }
        }
      }
    }
  }
}

.wrapper {
  background: #ffffff;
}
/deep/ .el-loading-spinner {
  top: 30%;
}
.more {
  color: #00924c;
  text-decoration: underline;
  font-size: 14px;
}
/deep/ .el-loading-mask {
  top: 28px;
  right: 70px;
}
/deep/ .el-loading-spinner {
  top: 5%;
}
.pages {
  padding-top: 90px;
}
</style>
